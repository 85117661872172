@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #666666;
  font-size: 15px;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden !important;
  margin: 0;
  font-family: "montserrat", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  list-style: none;
  text-decoration: none;
  color: inherit;
}

h2 {
  color: #302f2f;
}

h1 {
  font-weight: 500;
}

p {
  line-height: 20px;
}

.container {
  padding: 0 1.5em;
  max-width: 1200px;
  margin: 0 auto;
}

.menubgc {
  width: 100%;
  height: 8em;
  background-color: #2c2c2c;
}

@media (max-width: 800px) {
  .menubgc {
    height: 5.56em;
  }
}
/*# sourceMappingURL=App.css.map */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation {
  position: fixed;
  z-index: 100;
  width: 100vw;
  box-sizing: border-box;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 2em 5em;
  transition: 0.6s;
}

.navigation__logo {
  align-self: flex-end;
}

.navigation__logo-main {
  margin: 0;
  padding: 0;
  font-size: 2.6em;
  transition: 0.2s;
}

.navigation__logo-secondary {
  font-weight: 400;
  color: #2eb9f5;
}

.navigation__options {
  display: flex;
}

.navigation__list {
  padding: 0;
  margin: 0;
  display: flex;
  align-self: flex-end;
}

.navigation__list-item {
  list-style: none;
  display: flex;
}

.navigation__list-item a {
  align-items: flex-end;
  cursor: pointer;
  font-size: 1.1em;
  display: block;
  padding: 0 2em 0;
  box-sizing: border-box;
  border-bottom: 2px solid transparent;
  transition: 0.2s;
}

.navigation__list-item a:hover {
  color: #2eb9f5;
}

.navigation--fixed {
  background-color: #2c2c2c;
  padding: 1em 5em;
}

.navigation--fixed .navigation__logo-main {
  margin: 0;
  padding: 0;
  font-size: 2em;
}

.navigation--fixed .navigation__logo-main-secondary {
  font-weight: 400;
  color: #2eb9f5;
}

.navigation--fixed .navigation__options__list-item a {
  font-size: 1em;
}

.navigation__hamburger {
  display: none;
}

@media (max-width: 800px) {
  .navigation {
    padding: 2em 1em;
  }
  .navigation__logo {
    z-index: 99;
  }
  .navigation__logo-main {
    font-size: 1.5em;
  }
  .navigation__options {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10em 5em;
    opacity: 0;
    transition: 0.5s ease-in-out;
    pointer-events: none;
  }
  .navigation__options--active {
    background-color: #202020;
    opacity: 1;
    pointer-events: auto;
  }
  .navigation__list {
    display: flex;
    flex-direction: column;
  }
  .navigation__list-item {
    width: 100%;
  }
  .navigation__list-item a {
    color: #ffffffd3;
    font-weight: 400;
    padding: 0.3em 0;
    font-size: 1.2em;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  .navigation--fixed {
    padding: 1.2em 1em;
  }
  .navigation--fixed .navigation__options__list-item a {
    font-size: 1.2em;
  }
  .navigation--fixed .navigation__logo-main {
    margin: 0;
    padding: 0;
    font-size: 1.2em;
  }
  .navigation--fixed .navigation__logo-secondary {
    font-weight: 400;
    color: #2eb9f5;
  }
  .navigation__hamburger {
    -ms-grid-row-align: center;
    align-self: center;
    display: block;
  }
}
/*# sourceMappingURL=Menu.css.map */
.scrollMenu {
  display: none;
  color: #2c2c2c;
  font-weight: 500;
  position: fixed;
  top: 50%;
  left: 0px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: 1;
  transition: 0.5s;
}

.scrollMenu__dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  margin: 18px 8px;
  background-color: #d4d4d4;
  transition: 0.2s;
}

.scrollMenu__dot--small {
  -webkit-transform: scale(0.7) !important;
          transform: scale(0.7) !important;
  border-radius: 5px !important;
}

.scrollMenu--active .scrollMenu__dot {
  background-color: #028fcc;
  border: none;
  -webkit-transform: scale(1.6);
          transform: scale(1.6);
}

.scrollMenu--active .scrollMenu__dot--small {
  background-color: #028fcc;
  -webkit-transform: scale(0.7) !important;
          transform: scale(0.7) !important;
}

@media (min-width: 800px) {
  .scrollMenu {
    display: block;
    left: 20px;
    margin: 10px;
  }
  .scrollMenu__dot {
    height: 12px;
    width: 12px;
  }
}
/*# sourceMappingURL=MenuIndicators.css.map */
.header {
  height: 100vh;
  width: 100vw;
  background-image: url("/images/header2.jpg");
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: relative;
}

.header__text {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 3em;
}

.header__text-main {
  font-weight: 600;
  font-size: 1.7em;
  margin: 0.6em;
}

.header__text-main span {
  color: #028fcc;
}

.header__text-secondary {
  font-weight: 300;
  font-size: 6;
  margin: 0.2em;
  font-size: 15px;
}

.header__text-button {
  font-family: montserrat;
  background-color: transparent;
  color: white;
  font-size: 0.8em;
  font-weight: 300;
  margin-top: 1.8em;
  padding: 0.6em;
  flex-basis: auto;
  border: 1px solid #b1b1b1;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -ms-grid-row-align: center;
  align-self: center;
  transition: 0.2s;
  cursor: pointer;
}

.header__text-button:hover {
  background-color: #fff;
  color: #3a3a3a;
}

.header__icon {
  justify-self: flex-end;
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  transition: 0.5s;
}

.header__icon:hover {
  cursor: pointer;
  color: black !important;
}

@media (min-width: 800px) {
  .header {
    background-attachment: fixed;
  }
  .header__text {
    padding: 3em;
  }
  .header__text-main {
    font-weight: 600;
    font-size: 3em;
    margin: 0.2em;
  }
  .header__text-secondary {
    font-size: 15px;
  }
}
/*# sourceMappingURL=Header.css.map */
h2 {
  position: relative;
  margin-bottom: 1.8em;
  margin-top: 0.7em;
}

h2:after {
  content: "";
  position: absolute;
  top: 1.5em;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  background-color: #028fcc;
  height: 0.2em;
  width: 5em;
}

.offer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.offer__text {
  padding-top: 2em;
  text-align: center;
  font-size: 15px;
}

.offer__cards {
  font-size: 15px;
}

.card {
  color: #5e5e5e;
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  margin: 1em 0;
  transition: 0.1s;
}

.card__img {
  cursor: pointer;
  width: 100%;
  height: 320px;
  transition: 0.2s;
}

.card__img:hover {
  -webkit-transform: scale(0.99);
          transform: scale(0.99);
}

.card__img-wrapper {
  object-fit: cover;
}

.card__text {
  padding: 0 1.5em 2em;
}

.card__text-main {
  margin: 1em 0.5em 0.5em;
}

.card__list {
  padding: 0;
  list-style: none;
}

.card__list-item {
  font-weight: 400;
  font-size: 0.9em;
  margin: 0.1em;
  margin-left: 2em;
  position: relative;
}

.card__list-item:before {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: -20px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  height: 0.5em;
  width: 0.5em;
  background-color: #5e5e5e;
}

@media (min-width: 800px) {
  h2 {
    font-size: 2em;
    letter-spacing: 0.2em;
    margin-bottom: 1.8em;
    margin-top: 0.7em;
  }
  h2:after {
    content: "";
    position: absolute;
    top: 1.5em;
    left: 50%;
    -webkit-transform: translate(-50%);
            transform: translate(-50%);
    background-color: #028fcc;
    height: 0.3em;
    width: 5em;
  }
  .offer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .offer__text {
    padding-top: 2em;
    margin-bottom: 2em;
    text-align: center;
    font-size: 15px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }
  .offer__cards {
    font-size: 15px;
    display: flex;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
    flex-wrap: wrap;
    justify-content: center;
  }
  .card {
    color: #5e5e5e;
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    box-shadow: 0 35px 35px -25px rgba(10, 10, 10, 0.1);
    margin: 1em 0.5em;
  }
  .card--offer {
    width: 32%;
  }
  .card__img {
    width: 100%;
  }
  .card__img-wrapper {
    overflow: hidden;
  }
  .card__text {
    padding: 0.2em 2em 1.4em;
  }
  .card__text-main {
    margin: 1em 1em 0.5em 0.5em;
    font-size: 1.05em;
  }
  .card__text-main--seemore {
    text-align: center;
    font-size: 24px;
  }
  .card--seemore {
    width: 100%;
    height: 10vh;
    flex-direction: row;
    background-color: #fff;
  }
  .card--seemore .card__img {
    height: 100%;
    width: 33%;
  }
  .card__list {
    padding: 0;
    list-style: none;
  }
  .card__list-item {
    font-weight: 400;
    font-size: 0.9em;
    margin: 0.1em;
    margin-left: 2em;
    position: relative;
  }
  .card__list-item:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: -20px;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    height: 0.5em;
    width: 0.5em;
    background-color: #5e5e5e;
  }
  .job--offer {
    margin-top: 5em;
  }
  .job--offer .container {
    max-width: 1200px !important;
    justify-content: space-around;
  }
}
/*# sourceMappingURL=Offer.css.map */
.about {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.about__text {
  padding: 1.5em;
  margin-bottom: 1em;
}

.about__img {
  background-image: url("/images/company.jpg");
  background-size: cover;
  height: 40vh;
  width: 100vw;
}

.numbers {
  flex-basis: 100%;
  color: #f4f4f4;
  padding: 0 2em;
  background-color: #028fcc;
}

.numbers__list {
  max-width: 1200px;
  list-style: none;
  padding: 1.7em 0;
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(150px, 2fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(150px, 2fr));
}

.numbers__list .numbers__item {
  margin: 1em 0;
}

.numbers__list .numbers__item-number {
  font-size: 2.5em;
}

.numbers__list .numbers__item-description {
  display: block;
}

@media (min-width: 1200px) {
  .about {
    max-width: 100vw;
    overflow: hidden;
    background-color: #f9f9f9;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (50%)[2];
        grid-template-columns: repeat(2, 50%);
  }
  .about__text {
    align-items: flex-start;
    margin-bottom: 1em;
    margin-left: 20% !important;
    padding: 4em 5em;
  }
  .about__paragraph {
    text-align: left;
  }
  .about__img {
    height: 100%;
    background-attachment: fixed;
    background-position: 25% 60%;
  }
  .numbers__list {
    padding-left: 12em;
  }
  .numbers .numbers__item {
    position: relative;
  }
  .numbers .numbers__item::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 2px;
    border-radius: 3px;
    right: 6em;
    bottom: 0;
    background-color: #ebebeb;
  }
  .numbers__item:nth-last-child(1)::before {
    display: none;
  }
}
/*# sourceMappingURL=About.css.map */
.aplication {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.aplication h2:after {
  left: 2.5em;
}

.aplication__text {
  padding: 1.5em;
  margin-bottom: 1em;
}

.aplication__form {
  display: flex;
  flex-direction: column;
  margin: 3em 0 0;
}

.aplication__form input,
.aplication__form textarea {
  padding: 1.1em 1.5em;
  font-family: montserrat;
  border: 0;
  background-color: #f4f4f4;
  margin: 0.3em 0;
  border-radius: 0.5em;
}

.aplication__form input:focus,
.aplication__form textarea:focus {
  color: black;
}

.aplication__form__button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aplication__img {
  background-image: url("/images/company.jpg");
  background-size: cover;
  height: 40vh;
  width: 100vw;
  background-position-x: 500px;
}

@media (min-width: 1200px) {
  .aplication {
    max-width: 100vw;
    overflow: hidden;
    background-color: #f9f9f9;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (50%)[2];
        grid-template-columns: repeat(2, 50%);
  }
  .aplication__text {
    padding-top: 4em !important;
    box-sizing: border-box;
    padding-right: 5em;
    align-items: flex-start;
    margin-bottom: 1em;
    margin-left: 30% !important;
  }
  .aplication__text p {
    text-align: left;
  }
  .aplication__img {
    height: 100%;
    background-attachment: fixed;
    background-position: 25% 60%;
  }
}
/*# sourceMappingURL=Aplication.css.map */
.realization {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.realization__text {
  padding-top: 2em;
  text-align: center;
  font-size: 15px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.realization__text-paragraph {
  line-height: 20px;
  font-size: 15px;
}

.realization__cards {
  font-size: 15px;
  margin-bottom: 20vh;
}

@media (min-width: 800px) {
  .realization {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .realization__text {
    padding-top: 4em;
    text-align: center;
    font-size: 16px;
    padding-bottom: 4em;
  }
  .realization__cards {
    font-size: 15px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
  .realization .card:hover {
    -webkit-transform: translateY(8px);
            transform: translateY(8px);
  }
}
/*# sourceMappingURL=Realizations.css.map */
.partners {
  text-align: center;
  margin-top: -10vh;
  margin-bottom: 5vh;
}

.partners__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.partners__img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  max-width: 100%;
  margin: 0.4em;
  width: 40%;
}

.partners__img:hover {
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
}

.partners img {
  width: 100%;
  height: 100%;
}

@media (min-width: 800px) {
  .partners {
    text-align: center;
  }
  .partners__logos {
    display: flex;
    flex-wrap: nowrap;
  }
  .partners__img {
    -ms-grid-row-align: center;
    align-self: center;
    -ms-grid-column-align: center;
        justify-self: center;
    -webkit-filter: grayscale(100%);
            filter: grayscale(100%);
    width: 10%;
    margin: 0.4em;
  }
  .partners__img:hover {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
    cursor: pointer;
  }
}
/*# sourceMappingURL=Partners.css.map */
.job {
  background-color: #028fcc;
  padding: 3.4em 0;
  color: white;
}

.job__text-main {
  font-size: 2.2em;
  margin: 0;
}

.job__button {
  font-family: montserrat;
  background-color: transparent;
  color: white;
  font-size: 0.8em;
  font-weight: 300;
  margin-top: 1.8em;
  padding: 0.6em;
  flex-basis: auto;
  border: 1px solid white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  -ms-grid-row-align: center;
  align-self: center;
  transition: 0.2s;
  cursor: pointer;
}

.job__button:hover {
  background-color: #fff;
  color: #3a3a3a;
}

.map__google {
  height: 50vh;
  width: 100vw;
}

.contacts {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.contacts__form {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
}

.contacts__form input,
.contacts__form textarea {
  padding: 1.1em 1.5em;
  font-family: montserrat;
  border: 0;
  background-color: #f4f4f4;
  margin: 0.3em 0;
  border-radius: 0.5em;
}

.contacts__form input:focus,
.contacts__form textarea:focus {
  color: black;
}

.contacts__form-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contacts__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

.contacts__info h1 {
  font-size: 2em;
}

.contacts__info h1 span {
  font-weight: 400;
  color: #028fcc;
}

.contacts__info h1,
.contacts__info h2 {
  margin: 0.2em 0;
}

.contacts__info h2:after {
  display: none;
}

.contacts__info .destination {
  display: flex;
}

.contacts__info .info2 {
  text-align: left;
  padding: 1em 0;
  line-height: 1.4em;
}

@media (min-width: 800px) {
  .job .container {
    display: flex;
    max-width: 800px;
    margin: 0 auto !important;
  }
  .job__text-paragraph {
    margin-left: 0;
    padding-right: 3em;
  }
  .job__button {
    font-size: 1em;
    font-weight: 00;
    margin-top: 0.8em;
    padding: 15px 15px;
  }
  .map__google {
    height: 70vh;
    width: 100vw;
  }
  .contacts {
    flex-direction: row;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    margin: 2em auto 4em !important;
  }
  .contacts__info, .contacts__form {
    -ms-grid-row-align: center;
    align-self: center;
  }
}
/*# sourceMappingURL=Contact.css.map */
footer {
  width: 100vw;
  background-color: #028fcc;
}

.footer {
  padding: 1.2em 0 !important;
}

.footer__list {
  list-style: none;
  display: flex;
  flex-direction: column;
}

.footer__item {
  margin: 1em 0;
  display: flex;
  align-items: center;
}

.footer__item a {
  display: inline-block;
  color: #f4f4f4;
  text-decoration: none;
  margin: 0 1em;
}

.footer .icon-footer {
  font-size: 32px;
}

.footer__copyright {
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  padding: 0.5em 0 1em;
}

@media (min-width: 800px) {
  .footer__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (1fr)[3];
        grid-template-columns: repeat(3, 1fr);
  }
  .footer .icon-footer {
    font-size: 42px;
  }
}
/*# sourceMappingURL=Fotter.css.map */
.gallery {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  text-align: center;
  padding: 5vh 1em;
}

.gallery__text {
  padding-top: 2em;
  text-align: center;
  font-size: 15px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.gallery__text-paragraph {
  line-height: 20px;
  font-size: 15px;
}

.gallery__grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(50%, 1fr))[1fr];
      grid-template-columns: repeat(1fr, minmax(50%, 1fr));
}

.gallery__item {
  height: 35vh;
  overflow: hidden;
}

.gallery__item-img {
  height: 100%;
  width: 100%;
  transition: 0.2s;
  cursor: pointer;
  object-fit: cover;
}

.gallery__item-img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

@media (min-width: 800px) {
  .gallery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .gallery__text {
    padding-top: 4em;
    text-align: center;
    font-size: 16px;
    padding-bottom: 4em;
  }
  .gallery__grid {
    width: 80vw;
    margin: 0 auto 10vh;
    -ms-grid-columns: (minmax(20%, 1fr))[auto-fill];
        grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
  }
  .gallery__item {
    height: 35vh;
  }
}
/*# sourceMappingURL=Gallery.css.map */
.offer-details {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-size: 1.2em;
}

.offer-details__main-text {
  padding: auto 50px;
  letter-spacing: 2px;
}

.offer-details__main-text::after {
  top: 120%;
  left: 25%;
  height: 15px;
  z-index: -1;
}

.offer-details__text {
  padding: 5vh 1.5em;
  margin-bottom: 1em;
}

.offer-details__img {
  background-image: url("/images/company.jpg");
  height: 40vh;
  width: 100vw;
  background-attachment: scroll;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (min-width: 1200px) {
  .offer-details {
    max-width: 75vw;
    font-size: 1.5em;
    margin: auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: stretch;
  }
  .offer-details__text {
    box-sizing: border-box;
    align-items: flex-start;
    width: 50%;
  }
  .offer-details__text p {
    text-align: left;
  }
  .offer-details__text.left {
    order: 1;
    padding: 15vh 5vh 15vh;
  }
  .offer-details__text.right {
    order: 2;
    padding: 15vh 5%;
  }
  .offer-details__img {
    height: 100%;
    width: 50%;
    -ms-grid-column-align: stretch;
        justify-self: stretch;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
  }
  .offer-details__img.left {
    order: 1;
  }
  .offer-details__img.right {
    order: 2;
  }
}
/*# sourceMappingURL=OfferDetails.css.map */
.error404 {
  background-image: url("/images/header2.jpg");
  height: 100vh;
  -webkit-filter: blur(10px);
          filter: blur(10px);
}

.wrapper404 {
  position: relative;
}

.text404 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-weight: normal;
  color: #9e9e9e;
  font-size: 48px;
  text-align: center;
}
/*# sourceMappingURL=page404.css.map */
