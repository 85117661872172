@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-weight: 300;
  color: #666666;
  font-size: 15px;
  scroll-behavior: smooth;
  width: 100vw;
  overflow-x: hidden !important;
  margin: 0;
  font-family: "montserrat", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  list-style: none;
  text-decoration: none;
  color: inherit;
}

h2 {
  color: #302f2f;
}

h1 {
  font-weight: 500;
}

p {
  line-height: 20px;
}

.container {
  padding: 0 1.5em;
  max-width: 1200px;
  margin: 0 auto;
}

.menubgc {
  width: 100%;
  height: 8em;
  background-color: #2c2c2c;
}

@media (max-width: 800px) {
  .menubgc {
    height: 5.56em;
  }
}
/*# sourceMappingURL=App.css.map */

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  background-color: #fff;
  z-index: 999;
  overflow: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
